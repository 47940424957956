import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cause } from '@features/causes/causes.models';
import { Thematic, ThematicMedia } from '@features/thematics/thematics.models';
import { environment } from 'environments/environment';
import { forkJoin, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThematicsService {
  private readonly api = `${environment.adventureUri}/thematics`;

  constructor(private http: HttpClient) {}

  public getThematicAll(thematicUuid: string): Observable<Thematic> {
    const thematic$ = this.getThematic(thematicUuid);
    const causes$ = this.getCausesThematiques(thematicUuid);
    const medias$ = this.getMedias(thematicUuid);

    return forkJoin([thematic$, causes$, medias$]).pipe(
      map(([thematic, causes, medias]) => ({
        ...thematic,
        causes,
        medias
      }))
    );
  }

  public getThematicsCauses(): Observable<Thematic[]> {
    return this.http.get<Thematic[]>(`${this.api}/causes`);
  }

  public getCausesThematiques(thematicUuid: string): Observable<Cause[]> {
    return this.http.get<Cause[]>(`${this.api}/${thematicUuid}/causes/thematic-membership`);
  }

  private getThematic(thematicUuid: string): Observable<Thematic> {
    return this.http.get<Thematic>(`${this.api}/${thematicUuid}`);
  }

  private getMedias(thematicUuid: string): Observable<ThematicMedia[]> {
    return this.http.get<ThematicMedia[]>(`${this.api}/${thematicUuid}/medias`);
  }
}
